.post-list-heading {
  margin-top: 3 * 15px;
}

.home .post-list li {
  clear: both;
}

.home .post-list img {
  max-height: 250px;
}

.home .post-list li {
  & h1,
  & h2 {
    font-size: 16px;
    font-weight: 200;
  }
}

blockquote {
  font-style: normal;
  font-size: 75%;
}

.post h1 {
  clear: both;
}
