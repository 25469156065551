@import "minima";
@import "minima-overrides";
@import "accordion";

.header-image {
  z-index: -1;
  user-select: none;
  object-fit: cover;
  height: 250px;
  margin: -30px 0 15px;
}

.inline-image {
  display: inline-block;
  margin: 5px;
  height: 100px;
  width: auto;
  float: right;
  box-shadow: -2px 2px 4px grey;

  @media (min-width: 500px) {
    height: 200px;
  }

  // "reset" theme settings for images
  > img {
    height: 100%;
  }
}

// makes it pop out on hover
.interactive-image {
  // mobile click and onhover
  &:hover,
  &:active {
    box-shadow: -2px 2px 5px blue;
    transition-duration: 100ms;
    transform: scale(1.05);
  }
}

.u-full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}
// 100vw will cause horizontal scroll bar when there already is a vertical scroll bar. we need to hide it
// the alternative would require us to restructure the template html quite a bit
html {
  overflow-x: hidden;
}

.img--post {
  max-width: calc(100% - 20px);
  height: auto;
  width: auto;
  margin: 10px;
  border-radius: 3px;
  vertical-align: middle;
}

dl {
  display: grid;
  grid-template-columns: max-content auto;
}

dt {
  grid-column-start: 1;
  font-weight: 700;
  padding-right: 2em;
}

dd {
  grid-column-start: 2;
}

.u-1\/1 {
  width: 100%;
}

.u-text-centered\@desktop {
  @media (min-width: 500px) {
    text-align: center;
  }
}

.u-only-display\@mobile {
  @media (min-width: 500px) {
    display: none;
  }
}

.post-image {
  height: auto;
}

//https://www.ostraining.com/blog/coding/responsive-google-maps
// we have a chicken and egg problem with regards to the height here and javascript
// the iframe want to expand to 100% of its container, but the container normally gets
// its size from the content. So the container gets zero size and the iframe likewise.
// We use the padding property to get a height proportional to its height instead.
.map-responsive {
  padding-bottom: 100%; // 1:1 ratio - good for map where points are mostly vertical
  position: relative; // needed for absolute positioning in iframe
  margin-bottom: 24px;
}
@media (min-width: 500px) {
  .map-responsive {
    padding-bottom: calc(100% * 9 / 16);
  }
}

// make the iframe fill the entire container
.map-responsive iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

// google photo slideshow
.pa-embed-player {
  overflow: hidden; // sometimes strange rendering bugs in firefox on initial load
  padding-bottom: 2rem;
}

.hide {
  display: none;
}

// When you want to hide something, like tags, but want them present in the DOM
.visually-hidden {
  visibility: hidden;
  height: 0;
  width: 0;
}

.search__suggestion:not(:first-child):before {
  content: " ";
}

@media (max-width: 480px) {
  .hide\@mobile {
    display: none;
  }
}

#airbnb-injection {
  margin-bottom: 15px;
  background: #fafafa;
}

// copied from bootstrap
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
